import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useState } from "react";
import { MdOutlineTextsms } from "react-icons/md";
import { RefreshContext } from "../../../../ContextProvider";
import Button from "../../../../components/Reusables/Button";
import Modal from "../../../../components/Reusables/Modal";
import Spinner from "../../../../components/Utility/Spinner";
import { baseURL } from "../../../../helpers/fetchingURL";

export default function CallbackSMS({
  phone,
  name,
  orderNum,
  responseReference,
}) {
  const [showModal, setShowModal] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const { user, getAccessTokenSilently } = useAuth0();
  const { setRefreshData } = useContext(RefreshContext);

  const smsOptions = [
    {
      name: "Callback",
      text: `Please call Precision Pharmacy on 01284 703032 to discuss your recent order #${orderNum}.`,
    },
    {
      name: "Not suitable",
      text: "Hello, this product is not suitable for you due to your other medications, we recommend you speak to your GP so they can prescribe a safer dose and monitor you.",
    },
    {
      name: "Rejected",
      text: "Hello, we have rejected your order, as you do not meet the criteria to purchase this. Please ensure the patient this is for, places an order in their own name.",
    },
    {
      name: "Cancelled",
      text: "Hello, we have tried to make contact with you on a few occasions in regard to your order, as we are unable to get through to you and we have received no call back from you we have to cancel your order.",
    },
  ];

  const [selectedSMS, setSelectedSMS] = useState(0);

  useEffect(() => {
    console.log("selectedSMS", selectedSMS);
  }, [selectedSMS]);

  async function sendSMS() {
    setIsSending(true);
    const accessToken = await getAccessTokenSilently();
    console.log(selectedSMS.text);
    const response = await fetch(`${baseURL}/api/sms/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text: smsOptions[selectedSMS].text,
        type: smsOptions[selectedSMS].name,
        phone: phone,
        responseReference: responseReference,
        pharmacistName: user.nickname,
      }),
    });

    setRefreshData((prevState) => !prevState);
    setIsSending(false);
    setShowModal(false);
  }

  return (
    <>
      <Button className="whitespace-nowrap" onClick={() => setShowModal(true)}>
        <MdOutlineTextsms size="20px" />
        <span>Send Callback SMS</span>
      </Button>
      <Modal isOpen={showModal} setIsOpen={isSending ? () => {} : setShowModal}>
        <div className="flex flex-col  justify-center bg-white rounded  px-8 py-6">
          <div className="flex flex-col items-center justify-center space-y-3">
            <h1 className="text-2xl font-semibold">Send Callback SMS</h1>
            <p className="text-sm text-gray-500 ">
              Are you sure you want to send a callback SMS to this customer?
            </p>
            <p className="font-medium">
              Order #{orderNum} - {name}
            </p>
          </div>
          {/* <p className="text-sm text-gray-500 mt-3 mb-2 text-left">
            Please select a reason for sending SMS:
          </p> */}
          {/* <RadioGroupCards
            orderNum={orderNum}
            setSelectedSMS={setSelectedSMS}
            selectedSMS={selectedSMS}
            options={smsOptions}
          /> */}
          <div className="mt-4 grid grid-cols-2 space-x-2">
            <Button
              color="green"
              onClick={() => {
                sendSMS();
              }}
              disabled={isSending}
            >
              <Spinner show={isSending} className={"h-4"} />
              <span>Send</span>
            </Button>
            <Button
              onClick={() => {
                setShowModal(false);
              }}
              disabled={isSending}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
