import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

function ShopFilter({ shopFilter, setShopFilter, setCurrentPage }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const options = useMemo(() => {
    return [
      { label: "All", value: "" },
      { label: "Shopify", value: "CIALIS" },
      { label: "eBay", value: "EBAY" },
    ];
  }, []);

  function handleChange(e) {
    const currentParams = Object.fromEntries([...searchParams]);
    currentParams.shop = e.target.value;
    if (e.target.value === "") {
      delete currentParams.shop;
    }
    currentParams.page = 1;
    const urlParams = new URLSearchParams(currentParams).toString();
    setSearchParams(urlParams);
  }

  return (
    <div className="flex items-center gap-1">
      <select
        id={"shopFilter"}
        value={shopFilter}
        onChange={handleChange}
        className={`flex w-full cursor-pointer rounded-md border-gray-200 px-3 py-2 pr-9 text-sm shadow-sm focus:border-blue-500 focus:ring-blue-500 md:w-fit`}
				title="Select Shop"
      >
        {options.map((option) => (
          <option
            key={option.label}
            value={option.value}
            className="text-neutral-900"
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ShopFilter;
