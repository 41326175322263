import cialisProductInfo from "./cialisProductInfo";

export function displayPackQuantity(productId, productQuantity) {
  if (!productId || !productQuantity) {
    return null;
  }


  if (productId.includes("|")) {
    // productId  ='45102055489789 | 45102055457021'
    // productQuantity = '1 | 1'
    let productIds = productId.split("|");
    let packQuantities = productQuantity.split("|");

    const packs = productIds.map((id, index) => {
      return {
        packNumber: cialisProductInfo[id.trim()].packSize,
        productQuantity: packQuantities[index].trim(),
      };
    });

    return (
      <>
        <div className="text-ellipsis">
          {packs?.map((pack, index) => (
            <p>
              CT{pack.packNumber}x<strong>{pack.productQuantity}</strong>
            </p>
          ))}
        </div>
      </>
    );
  }

  console.log("productId", productId);

  if (!cialisProductInfo[productId]) {
    return null;
  }

  return (
    <>
      <p className="text-ellipsis">
        CT{cialisProductInfo[productId].packSize}x
        <strong>{productQuantity}</strong>
      </p>
    </>
  );
}
