import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BiNotepad } from "react-icons/bi";
import { MdOpenInNew, MdOutlineTextsms } from "react-icons/md";
import { Link, useSearchParams } from "react-router-dom";
import {
  useAsyncDebounce,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from "react-table";
import { RefreshContext } from "../../ContextProvider";
import { baseURL } from "../../helpers/fetchingURL";
import ApprovalStatus from "../ApprovalStatus/ApprovalStatus";
import { useDebounce } from "../Hooks/useDebounce";
import useLoadingState from "../Hooks/useLoadingState";
import Checkbox from "./Checkbox";
import Filter from "./Filter";
import Pagination from "./Pagination";
import Search from "./Search";
import Skeleton from "./Skeleton";

import { MdOutlineContactPhone } from "react-icons/md";
import { displayPackQuantity } from "../../helpers/dataFormatting";
import FrequentPurchaserModal from "../Modals/FrequentPurchaserModal";
import ShopFilter from "./ShopFilter";
// import { fetchEventSource } from "@microsoft/fetch-event-source";

const Table = (props) => {
  const [data, setData] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState(null);
  const [limit, setLimit] = useState(initialLimit);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [pharmaStatus, setPharmaStatus] = useState(initialStatus);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [shopFilter, setShopFilter] = useState(initialShopFilter);

  const [freqPurchaserModal, setFreqPurchaserModal] = useState(false);
  const [freqPurchaserData, setFreqPurchaserData] = useState({});

  // Get access token from auth0
  const { getAccessTokenSilently } = useAuth0();

  const debouncedSearchTerm = useDebounce(searchTerm, 400);
  // Search params
  const [searchParams, setSearchParams] = useSearchParams();

  const { refreshData } = useContext(RefreshContext);

  // Check if screen has been scrolled down
  const [top, setTop] = useState(true);
  const divRef = useRef(null);

  useEffect(() => {
    const scrollHandler = () => {
      if (divRef.current.scrollTop > 0) {
        setTop(false);
      } else {
        setTop(true);
      }
    };

    const div = divRef.current;
    if (div) {
      div.addEventListener("scroll", scrollHandler);
      return () => div.removeEventListener("scroll", scrollHandler);
    }
  }, []); // Empty dependency array to set up the listener once

  /*
		Set the initial table state based on query params use native js instead of react-router-dom useSearchParams() hook for speed.
	*/
  function initialPage() {
    const params = new URLSearchParams(window.location.search);
    const page = params.get("page");
    if (page) {
      return Number(page);
    } else {
      return 1;
    }
  }

  function initialLimit() {
    const params = new URLSearchParams(window.location.search);
    const limit = params.get("limit");
    if (limit) {
      return Number(limit);
    } else {
      return 50;
    }
  }

  function initialStatus() {
    const params = new URLSearchParams(window.location.search);
    const status = params.get("status");
    if (status) {
      return status;
    } else {
      return "";
    }
  }

  function initialSearchTerm() {
    const params = new URLSearchParams(window.location.search);
    const searchTerm = params.get("search");
    if (searchTerm) {
      return searchTerm;
    } else {
      return "";
    }
  }

  function initialShopFilter() {
    const params = new URLSearchParams(window.location.search);
    const shop = params.get("shop");
    if (shop) {
      return shop;
    } else {
      return "";
    }
  }

  useEffect(() => {
    // Get shop filter from query params
    let shop = searchParams.get("shop");
    if (shop !== null && shop !== undefined) {
      setShopFilter(shop);
    } else {
      setShopFilter("");
    }
  }, [searchParams.get("shop")]);

  useEffect(() => {
    // Get page number from query params
    let pageNum = searchParams.get("page");
    if (pageNum !== null && pageNum !== undefined) {
      setCurrentPage(Number(pageNum));
    } else {
      setCurrentPage(1);
    }
  }, [searchParams.get("page")]);

  useEffect(() => {
    // Get page rows limit from query params
    let pageLimit = searchParams.get("limit");
    if (pageLimit !== null && pageLimit !== undefined) {
      setLimit(Number(pageLimit));
    }
  }, [searchParams.get("limit")]);

  useEffect(() => {
    // Get pharma status from query params
    let pharmaStatus = searchParams.get("status");
    if (pharmaStatus !== null && pharmaStatus !== undefined) {
      setPharmaStatus(pharmaStatus);
    } else {
      setPharmaStatus("");
    }
  }, [searchParams.get("status")]);

  useEffect(() => {
    let searchTerm = searchParams.get("search");
    if (searchTerm !== null && searchTerm !== undefined) {
      setSearchTerm(searchTerm);
    } else {
      setSearchTerm("");
    }
  }, [searchParams.get("search")]);

  let [fetchData, fetchingData, pendingFetchData] = useLoadingState(
    async function (abortController) {
      setError(null);

      const accessToken = await getAccessTokenSilently();

      let requestOptions = {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        signal: abortController.signal, // Pass the signal to the fetch options
      };

      const shopFilterStr = shopFilter ? `&shop=${shopFilter}` : "";
      // Query params
      let query = `?page=${currentPage}&limit=${limit}${shopFilterStr}`;
      if (pharmaStatus !== "") {
        query += `&status=${pharmaStatus.toLowerCase()}`;
      }
      if (searchTerm !== "") {
        query += `&search=${searchTerm}`;
      }

      try {
        setPageSize(limit);
        const response = await fetch(
          `${baseURL}/api/order${query}`,
          requestOptions,
        );
        const responseContent = await response.json();

        console.log(responseContent);
        if (responseContent.data) {
          setData(responseContent.data);

          let total = Math.ceil(responseContent.total / limit);
          setTotalOrders(responseContent.total);
          setTotalPages(total);
        }
      } catch (error) {
        if (error.message === "The user aborted a request.") {
          console.log(error.message);
          return;
        }
        setError(
          "Error fetching data. If the error persists please contact support.",
        );
      }
    },
    { delayMs: 1000 },
  );

  const debouncedFetchData = useAsyncDebounce(
    (abortController) => fetchData(abortController),
    200,
  ); // Debounce delay of 300ms

  useEffect(() => {
    const abortController = new AbortController();

    debouncedFetchData(abortController);

    return () => {
      // cleanup fetch
      abortController.abort("Fetch aborted by user");
    };
  }, [currentPage, limit, pharmaStatus, debouncedSearchTerm, refreshData, shopFilter]);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "order_number",
        Cell: (props) => (
          <div className="flex items-center space-x-3">
            <Link
              className="flex w-fit items-center space-x-1 font-medium hover:underline"
              to={`/order/${props.row.original.order_number}`}
              state={{ data: props.row.original }}
            >
              <p className="font-mono font-bold">
                {props.row.original.order_number}
              </p>
              <MdOpenInNew size={16} />
            </Link>
            <div className="flex space-x-2">
              {props.row.original.HasUserNotes === 1 && (
                <BiNotepad size={16} title="Has user notes" />
              )}
              {props.row.original.hadSMS === 1 && (
                <MdOutlineTextsms size={16} title="SMS sent" />
              )}
              {props.row.original.contacted === 1 && (
                <MdOutlineContactPhone size={16} title="Approval Contact" />
              )}
            </div>
          </div>
        ),
      },
      {
        Header: "Pharmacy Status",
        accessor: "pharmacy_status",
        Cell: (props) => (
          <>
            <ApprovalStatus status={props.row.original.pharmacy_status} />
          </>
        ),
      },
      {
        Header: "Name",
        accessor: "shipping_name",
      },
      {
        Header: "Partner Purchase",
        accessor: "partner_purchase",
        Cell: (props) => <>{props.value ? "Yes" : "No"}</>,
      },
      {
        Header: "Order Date",
        accessor: "created_date",
        Cell: (props) => (
          <>
            {
              // convert date to dd/mm/yyyy and short time no seconds
              new Date(props.row.original.ResponseTime).toLocaleString(
                "en-GB",
                {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                },
              )
            }
          </>
        ),
      },
      {
        Header: "Email",
        accessor: "contact_email",
        Cell: (props) => (
          <>
            <a
              className="hover:underline"
              href={`mailto:${props.row.original.contact_email}`}
            >
              {props.row.original.contact_email}
            </a>
          </>
        ),
      },
      {
        Header: "Phone",
        accessor: "customer_phone",
      },
      {
        Header: "Tablets",
        accessor: "product_quantity",
        Cell: (props) => (
          <>
            {props.value
              ? displayPackQuantity(props.row.original.product_id, props.value)
              : null}
          </>
        ),
      },
      {
        Header: "Shipping Method",
        accessor: "ShippingMethod",
      },
      {
        Header: "Identity Check",
        accessor: "id_check_pass",
        Cell: (props) => <>{props.value ? "Pass" : "Fail"}</>,
      },
      {
        Header: "Gender Check",
        accessor: "gender_check_male",
        Cell: (props) => <>{props.value ? "Pass" : "Fail"}</>,
      },
      {
        Header: (props) => <div className="pl-2">FREQUENT PURCHASER</div>,
        accessor: "FrequentPurchaseCheck",
        Cell: ({ row, isLoading, isExpanded, value }) => {
          // const toggleRowExpandedProps = row.getToggleRowExpandedProps();

          if (value == false) return <div className="pl-2">{"No"}</div>;

          const onClick = (event) => {
            event.stopPropagation();
            // toggleRowExpandedProps.onClick(event);
            console.log("clicked");
            setFreqPurchaserData(row.original);
            setFreqPurchaserModal(true);
          };

          return (
            <div
              className="flex w-fit items-center space-x-1 rounded p-1 hover:bg-slate-100"
              onClick={onClick}
            >
              <div className="pl-1">Yes</div>
              <MdOpenInNew size={16} color="rgb(107, 114, 128)" />
            </div>
          );
        },
        // SubCell: (props) => (props.value ? "Yes" : "No"), // No expander on an expanded row
      },
    ],
    [],
  );

  // Create a function that will render our row sub components
  // const renderRowSubComponent = React.useCallback(
  //   ({ row, rowProps, visibleColumns }) => (
  //     <SubRowAsync
  //       row={row}
  //       rowProps={rowProps}
  //       visibleColumns={visibleColumns}
  //     />
  //   ),
  //   [],
  // );

  const tableColumns = React.useMemo(
    () =>
      pendingFetchData
        ? columns.map((column) => ({
            ...column,
            Cell: <Skeleton />,
          }))
        : columns,
    [pendingFetchData, columns],
  );

  const initialState = {
    hiddenColumns: JSON.parse(localStorage.getItem("hiddenColumns")) || [],
  };

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    visibleColumns,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    getToggleHideAllColumnsProps,
    setPageSize,
    selectedFlatRows,
    state,
    allColumns,
    setGlobalFilter,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns: columns,
      data,
      initialState,
    },
    useGlobalFilter,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div className="flex h-5 items-center">
              <Checkbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div className="flex h-5 items-center">
              <Checkbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          SubCell: () => null,
        },
        ...columns,
      ]);
    },
  );

  useEffect(() => {
    // Save hidden columns to local storage
    localStorage.setItem("hiddenColumns", JSON.stringify(state.hiddenColumns));
  }, [state]);

  useEffect(() => {
    props.setSelectedRows(selectedFlatRows.map((d) => d.original));
  }, [selectedFlatRows]);

  return (
    <>
      {freqPurchaserModal && (
        <FrequentPurchaserModal
          isOpen={freqPurchaserModal}
          setIsOpen={setFreqPurchaserModal}
          rowData={freqPurchaserData}
        />
      )}
      {pendingFetchData && (
        <div className="absolute left-1/2 top-0 z-50 -translate-x-1/2 rounded-sm border border-yellow-500 bg-yellow-200 px-4 py-2 text-sm font-medium drop-shadow">
          Loading...
        </div>
      )}
      <div className="bg-white">
        <div className="w-full divide-y divide-gray-200">
          <div
            className={`left-0 top-[64px] z-10 flex-col space-y-3 bg-white px-4 py-3`}
          >
            <div className="flex justify-between">
              <div className="flex items-center space-x-2 font-semibold">
                <h1 className="text-2xl">Orders </h1>
                <span className="rounded-lg bg-emerald-100 px-2 py-1 text-emerald-700">
                  {totalOrders}
                </span>
              </div>
            </div>
            <div className="flex w-full flex-col justify-between gap-2 md:flex-row">
              <div className="flex flex-col gap-2 md:flex-row">
                <ShopFilter shopFilter={shopFilter} />
                <Filter pharmaStatus={pharmaStatus} />
              </div>
              <div className="flex gap-2">
                <Search searchTerm={searchTerm} />
              </div>
              <Pagination
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageCount={pageCount}
                pageOptions={pageOptions}
                pageIndex={state.pageIndex}
                pageSize={state.pageSize}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                limit={limit}
                setLimit={setLimit}
              />
            </div>
          </div>

          <div
            className="flex h-[calc(100vh-309px)] w-full max-w-full flex-1 flex-col overflow-auto md:h-[calc(100vh-171px)]"
            ref={divRef}
          >
            <table className="relative min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className={`sticky top-0 z-10 ${
                      !top && `shadow-[0_-1px_0_rgb(229,231,235)]`
                    }`}
                  >
                    {headerGroup.headers.map((column) => {
                      return (
                        <th
                          scope="col"
                          className={`relative whitespace-nowrap bg-gray-50 px-2 py-2 text-left text-xs font-medium uppercase text-gray-500 shadow  md:px-6 md:py-3 ${
                            !top &&
                            ` after:absolute after:bottom-0 after:left-0 after:-z-10 after:h-1 after:w-full after:shadow after:content-[''] `
                          }`}
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              {error == null && (
                <tbody
                  className="divide-y divide-gray-200"
                  {...getTableBodyProps()}
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    const rowProps = row.getRowProps();

                    return (
                      <>
                        <tr
                          {...row.getRowProps()}
                          className={`group cursor-pointer  ${
                            row.isExpanded && "  "
                          }`}
                          onClick={() => {
                            row.toggleRowSelected();
                          }}
                        >
                          {row.cells.map((cell) => (
                            <td
                              className={`text-ellipsis whitespace-nowrap px-2 py-2 text-sm text-gray-800 transition-all md:px-6 md:py-2 ${
                                row.isSelected
                                  ? " bg-blue-50 "
                                  : " bg-white group-hover:bg-blue-50/30 "
                              }
                              
                              `}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                        {/* {row.isExpanded &&
                          renderRowSubComponent({
                            row,
                            rowProps,
                            visibleColumns,
                          })} */}
                      </>
                    );
                  })}
                  {/* if page rows are less than limit render dummy rows */}
                  {page.length < limit &&
                    [...Array(limit - page.length)].map((_, i) => (
                      <tr className="h-12" key={"dummy_row_" + i}></tr>
                    ))}
                </tbody>
              )}
            </table>

            {error != null && (
              <div className="flex h-40 items-center justify-center">
                <span className="text-gray-400">{error}</span>
              </div>
            )}
          </div>

          {Object.keys(state.selectedRowIds).length > 0 && (
            <div className="fixed bottom-10 left-1/2 flex -translate-x-1/2 items-center justify-end space-x-2 rounded-lg border bg-white px-4 py-2 text-sm font-medium drop-shadow-lg">
              <p className="">
                {Object.keys(state.selectedRowIds).length} selected
              </p>
              <button
                className="rounded-md border px-3 py-1 shadow-sm"
                onClick={() => toggleAllRowsSelected(true)}
              >
                Select all
              </button>
              <button
                className={`rounded-md border px-3 py-1 shadow-sm`}
                onClick={() => toggleAllRowsSelected(false)}
              >
                Unselect all
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Table;
