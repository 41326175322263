import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import Dropdown from "../Reusables/Dropdown";

function Filter({ pharmaStatus }) {
    const [searchParams, setSearchParams] = useSearchParams();

    const options = useMemo(() => {
        return ["Passed", "Partner Passed", "Needs Approval", "Approved", "Rejected", "Dispatched"];
    }, []);

    function handleChange(e) {
        const currentParams = Object.fromEntries([...searchParams]);
        currentParams.status = e.target.value;
        if (e.target.value === "") {
            delete currentParams.status;
        }
        currentParams.page = 1;
        const urlParams = new URLSearchParams(currentParams).toString();
        setSearchParams(urlParams);
    }

    return (
        <div className="flex items-center gap-1">
            <Dropdown
                id="approvalFilter"
                value={pharmaStatus}
                options={options}
                onChange={handleChange}
                placeholder="Select Approval Status"
            />
        </div>
    );
}

export default Filter;