const cialisProductInfo = {
	// "STAGING"
	45102055457021: {
		packSize: 4
	},
	45102055489789: {
		packSize: 8
	},
	// "LIVE"
	45501927162178: {
		packSize: 4
	},
	45501927260482: {
		packSize: 8
	},
}


module.exports = cialisProductInfo;