import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { BiX } from "react-icons/bi";
import { MdOpenInNew } from "react-icons/md";
import { Link } from "react-router-dom";
import { displayPackQuantity } from "../../helpers/dataFormatting";
import { baseURL } from "../../helpers/fetchingURL";
import ApprovalStatus from "../ApprovalStatus/ApprovalStatus";
import Modal from "../Reusables/Modal";
import Spinner from "../Utility/Spinner";

function FrequentPurchaserModal({ isOpen, setIsOpen, rowData }) {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setLoading(true);
    fetchRelevantOrders(rowData).then((data) => {
      console.log("data", data);
      setData(data);
      setLoading(false);
    });
  }, []);

  async function fetchRelevantOrders(rowData) {
    console.log("fetchRelevantOrders", rowData);
    const accessToken = await getAccessTokenSilently();

    try {
      let requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Add this line to set the content type to JSON
        },
        body: JSON.stringify({
          // Convert the body object to JSON
          order_date: rowData.created_at,
          responseReference: rowData.ResponseReference,
          contact_email: rowData.contact_email,
          curOrderTablets: rowData.NumberOfTablets,
          billing_address: {
            address1: rowData.billing_address1,
            address2: rowData.billing_address2,
            zip: rowData.billing_zip,
            phone: rowData.billing_phone,
          },
          shipping_address: {
            address1: rowData.shipping_address1,
            address2: rowData.shipping_address2,
            zip: rowData.shipping_zip,
            phone: rowData.shipping_phone,
          },
        }),
      };

      const response = await fetch(
        `${baseURL}/api/order/frequent-orders`,
        requestOptions,
      );
      const responseContent = await response.json();

      console.log("responseContent", responseContent);

      return responseContent;
    } catch (error) {
      console.error("Error fetching relevant orders:", error);
    }
  }

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="max-w-sm overflow-hidden rounded bg-white shadow sm:max-w-xl lg:max-w-full">
        <div className="flex items-center justify-between border-b px-6 py-3 text-lg font-medium">
          <h1>Order #{rowData.order_number}</h1>
          <div
            className="rounded-full p-2 hover:cursor-pointer hover:bg-gray-50"
            onClick={() => setIsOpen(false)}
          >
            <BiX size="20px" />
          </div>
        </div>
        <div className="flex h-full w-80 flex-col">
          <div className=""></div>

          <div className="border-b px-6 py-3">
            <div className="flex justify-between">
              <div className="flex w-full flex-col truncate">
                <p className="mb-2 text-xl font-semibold leading-none">
                  {rowData.customer_first_name +
                    " " +
                    rowData.customer_last_name}
                </p>
                <div className="truncate">
                  <a href={"mailto:" + rowData.contact_email}>
                    {rowData.contact_email}
                  </a>
                </div>
                <a href={"tel:" + rowData.customer_phone}>
                  {rowData.customer_phone}
                </a>
              </div>
              <div>
                {displayPackQuantity(
                  rowData.product_id,
                  rowData.product_quantity,
                )}
              </div>
            </div>
            <div className="mt-6 font-medium">
              Previous Orders ({data.length})
            </div>
          </div>

          {loading && (
            <div className="flex h-60 items-center sm:h-96">
              <div className="mx-auto h-8 w-8 text-blue-400">
                <Spinner />
              </div>
            </div>
          )}

          {!loading && (
            <div className="h-60 w-full space-y-4 overflow-auto p-6 sm:h-96">
              {data.map((order) => (
                <Link
                  className="flex w-full justify-between rounded p-4 shadow hover:bg-slate-50"
                  to={`/order/${order.order_number}`}
                  state={{ data: order }}
                >
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center space-x-1 font-medium">
                      <p>#{order.order_number}</p> <MdOpenInNew size={16} />
                    </div>
                    <ApprovalStatus status={order.pharmacy_status} />
                    <p>
                      {new Date(order.ResponseTime).toLocaleString("en-GB", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </p>
                  </div>

                  <div className="flex flex-col text-right">
                    {displayPackQuantity(
                      order.product_id,
                      order.product_quantity,
                    )}
                  </div>
                </Link>
              ))}

            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default FrequentPurchaserModal;
